import React from "react"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from "../components/content"
import Sidebar from "../components/sidebar"

class IndexPage extends React.Component {
  render(){
    return (
      <Layout page="home" sections={this.props.pageContext.sections} lang={this.props.pageContext.loc} dir={this.props.pageContext.loc === "he-il"? "rtl":"ltr"}>
        <SEO title="Kartos Boreas" lang={this.props.pageContext.loc} dir={this.props.pageContext.loc === "he-il"? "rtl":"ltr"}/>
        <Content page="home" sections={this.props.pageContext.sections} lang={this.props.pageContext.loc} dir={this.props.pageContext.loc === "he-il"? "rtl":"ltr"}/>
        <Sidebar page="home" lang={this.props.pageContext.loc} content={_.find(this.props.pageContext.sections, {"section_title": "sidebar"})}/>
      </Layout>
    )
  }
}

export default IndexPage
